.MuiPickersDatePickerRoot-toolbar {
  background: #f3eeea;
}

.MuiPickersDatePickerRoot-toolbar button span h6 {
  color: #94b7db;
}

.MuiPickersDatePickerRoot-toolbar button span h4 {
  color: #001640;
  font-size:24px;
  font-weight: 700;
}

.MuiPickersModal-dialogRoot .MuiDialogActions-root {
  display: flex;
  justify-content: space-between;
}
.MuiPickersModal-dialogRoot .MuiDialogActions-root button {
  background: transparent;
  border: 2px solid transparent;
  width: 47%;
  border-radius: 2px;
  text-transform: capitalize;
}

.MuiPickersModal-dialogRoot .MuiDialogActions-root button:hover {
  border: 2px solid #001640;
}

.MuiPickersModal-dialogRoot .MuiDialogActions-root button:focus {
  border: 2px solid #001640;
}

.MuiPickersModal-dialogRoot .MuiDialogActions-root button span {
  color: #001640;
  font-weight: 500;
}

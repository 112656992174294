.DownloadButton-optionsDiv {
  position: absolute;
  display: flex;

  height: 100%;
  right: 0;

  padding-right: 3px;
  z-index: -5;
  opacity: 0;
  transition: all 0.2s;
  pointer-events: none;
}

.DownloadButton-optionsDiv.open {
  opacity: 1;
  z-index: inherit;
  right: 100%;
  pointer-events: unset;
}
